import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/layouts/site-layout/index.tsx";
import { graphql } from "gatsby";
import { Banner, Work, Social } from "@components";
export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				work {
					from
					to
					name
					tech
					link
				}
				social {
					linkedin {
						url
						label
					}
					mail {
						url
						label
					}
					git {
						url
						label
					}
					codepen {
						url
						label
					}
					stackoverflow {
						url
						label
					}
				}
			}
		}
	}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
    <Banner greeting="Hello!! world" mdxType="Banner">
	<h1>I am Ajit</h1>
	<p>I build stuff for the web.</p>
    </Banner>
    <Social navData={Object.values(props.data.site.siteMetadata.social)} mdxType="Social" />
    <Work portfolio={props.data.site.siteMetadata.work} mdxType="Work" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      